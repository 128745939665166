import React from 'react';
import {Link} from "react-router-dom";

const Logo = () => (
                <Link to={"/"} className="border-highlightColor hover:text-third_color text-highlightColor transition-all hover:bg-highlightColor border-4 rounded-2xl p-2 w-auto h-auto">
                    <div className={"font-bold"}>
                        MN
                    </div>
                </Link>
);

export default Logo;