import '../styles/App.css';
import {Link} from "react-router-dom";

function About() {
    return (
        <div className="text-textColor_1 grid-rows-2 justify-center mt-[15%] text-2xl">
            <div className="flex justify-center text-8xl">
                404
            </div>
            <div className="flex justify-center">
                Ooops, looks like the Page you´re looking for isn´t there (anymore)
            </div>
            <div className="flex justify-center text-textColor_2 text-xl border-highlightColor mt-5">
                <Link to={""} className="border-8 border-highlightColor p-3 rounded-2xl transition-all hover:bg-highlightColor hover:text-third_color">
                    Take me Home
                </Link>
            </div>
        </div>
    );
}

export default About;
