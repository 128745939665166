import '../styles/App.css';
import Layout from "../components/Layout";

function About() {
    return (
        <Layout>
            <a>About</a>
        </Layout>
    );
}

export default About;
