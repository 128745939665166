import '../styles/App.css';
import Layout from "../components/Layout";

function Contact() {
    return (
        <Layout>
            <h1 className="text-center text-textColor_1 text-4xl mt-20 mb-10">Please reach out to me via E-Mail for now</h1>
            <div className="flex justify-center">
                <a className="border-2 hover:text-highlightColor rounded-3xl text-textColor_2 hover:shadow-third_color transition-all hover:shadow-md border-highlightColor ml-1 p-2 mb-96 mt-6" href="mailto:marcel@ndrecaj.dev">Contact me</a>
            </div>
        </Layout>
    );
}

export default Contact;
