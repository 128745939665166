import React from 'react';
import {Link} from "react-router-dom";

const Layout = ({ LinkRef, Name, Num }) => (
    <Link to={`/${LinkRef}`} className="flex hover:text-highlightColor transition-all">
        <div className="text-highlightColor md:pr-2">
            {Num}.
        </div>
        <div>
            {Name}
        </div>
    </Link>
);


export default Layout;