import Links from "./Links";
import HighlightedBtn from "./HighlightedBtn";
import Logo from "./Logo";
import React from "react";
import {Link} from "react-router-dom";

const Layout = ({ children }) => (


    <div>
        <header className="md:grid md:grid-cols-2 md:sticky">
            <div className="flex ml-[3%] md:ml-[8%] mt-[3%]">
                <Logo/>
            </div>
            <div
                className="md:flex hidden justify-end md:space-x-[3%] space-x-[1%]  mt-[4%] text-sm text-textColor_2 mr-0 md:[8%]">
                <Links LinkRef={"legal"} Name={"Legal"} Num={"1"}/>
                <HighlightedBtn/>
            </div>
        </header>
        <div className="mt-2 mb-2">
            {children}
        </div>
        <footer className={"mt-auto relative bottom-0 w-screen  bg-primaryColor"}>
            <div className={"md:flex hidden justify-center border-t border-t-highlightColor"}>
                <div className="mt-[1.5%] mb-[1.5%] text-sm text-textColor_2">
                    <span>Proudly built & styled by myself with React and TailwindCSS. </span>
                    <a href="https://github.com/MarcelNd/Portfolio"
                       className="border-b border-b-highlightColor hover:text-highlightColor transition-all">Check it
                        out on Github.</a>
                </div>
            </div>
            <div className={"md:hidden"}>
                <div className={"flex justify-center border-t space-x-10 border-t-highlightColor"}>
                    <Link to={`/legal`} className="flex mb-[5%] mt-[5%] text-textColor_2 border-4 border-highlightColor p-2 rounded-2xl">
                        Legal
                    </Link>
                    <Link to={`/legal`} className="flex mb-[5%] mt-[5%] text-textColor_2 border-4 border-highlightColor p-2 rounded-2xl">
                        Contact Me
                    </Link>
                </div>
                <div className={"grid justify-center grid-rows-2 text-center ml-14 mr-14 text-textColor_2"}>
                    <span>Proudly built & styled by myself with React and TailwindCSS. </span>
                </div>
            </div>
        </footer>
    </div>
);

export default Layout;