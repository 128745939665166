import { Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Home from './sites/Home';
import Technologies from './sites/Technologies';
import About from './sites/About';
import Legal from './sites/Legal';
import NotFound from './sites/NotFound';
import LoadingScreen from './components/LoadingScreen';
import Status from './sites/Status';
import Contact from './sites/Contact';

const App = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Hier könntest du Daten laden oder eine zeitliche Verzögerung hinzufügen
        setTimeout(() => setLoading(false), 1500);
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/defaultsite" element={<Home />} />
                <Route path="/technologies" element={<Technologies />} />
                <Route path="/about" element={<About />} />
                <Route path="/legal" element={<Legal />} />
                <Route path="/technologies/status" element={<Status />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default App;