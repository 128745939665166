import '../styles/App.css';
import Layout from "../components/Layout";
import TypingEffect from "../components/TypingEffect";
import {Link} from "react-router-dom";
import React from "react";
import CreatorImage from "../styles/Creator_Image.jpeg";
import ExampleProject from "../components/ExampleProject";
import ExampleProject2 from "../components/ExampleProject2";
function Home() {
    return (
        <Layout>
            <div>
                <div className="md:flex grid grid-rows-2 space-x-[22%] pt-[12%] md:pl-[3%] md:pr-[2.5%] pl-[7%] pr-[7%]">
                    <div className="grid col-auto">
                        <span className={'text-highlightColor pb-[1%] md:text-xl'}> Hi, my Name is</span>
                        <span className={'text-textColor_1 md:text-8xl text-6xl'}>Marcel Ndrecaj</span>
                        <div className={'text-textColor_1 md:text-2xl text-sm'}>
                            <TypingEffect/>
                        </div>
                        <div className="mt-6 ">
                        <Link to={"/technologies"}
                              className="text-textColor_1 md:text-1xl transition-all text-1xl hover:text-highlightColor">
                            See my Techstack →
                        </Link>
                        </div>
                        <div className="h-28">

                        </div>
                    </div>
                    <div className="justify-center pl-0">
                        <Link to={"/about"} className={"sm:w-[300px] w-52 h-auto"}>
                            <img src={CreatorImage} alt="Creator Image" className="rounded-2xl shadow-third_color shadow-2xl sm:w-[300px] mr-14 w-52 h-auto"/>
                        </Link>
                    </div>
                </div>
                <div className="flex justify-center space-x-2.5 mt-[7%] mb-28 md:text-2xl text-xl animate-bounce">
                    <p className="text-highlightColor">↓</p>
                    <p className="text-textColor_2">About Me</p>
                    <p className="text-highlightColor">↓</p>

                </div>
                <div className=" space-y-24">
                    <div className="space-y-10">
                    <h2 className="text-highlightColor mb-5 text-4xl underline text-center">Work</h2>
                    <div className="space-y-6">
                        <div>
                            <h2 className="text-highlightColor mb-2 text-2xl text-center">IT-Security & Computer
                                Science</h2>
                            <p className="md:mx-[25%] mx-[5%] text-textColor_2 text-center">Pursuing a Degree (B.Sc) in Computer
                                Science with a strong focus in IT Security at the well renown DHBW (Duale Hochschule
                                Baden-Württemberg). This educational path allows me to blend rigorous academic
                                coursework
                                with extensive hands-on experience, courtesy of a dynamic partnership with Bosch, a
                                global leader in technology and engineering.
                                <br/> <br/>
                                Over the course of my studies, I am privileged to gain 1.5 years of practical experience
                                within Bosch's innovative and diverse corporate environment. This blend of theoretical
                                knowledge and real-world application allows me to acquire a comprehensive understanding
                                of IT security challenges and solutions, preparing me
                                to contribute effectively to solutions for tomorrows questions in the field of cyber
                                security.</p>
                        </div>
                    </div>
                    </div>
                <div className="space-y-10">
                    <h2 className="text-highlightColor mb-5 text-4xl underline text-center">Side Stuff</h2>
                    <div className="space-y-6">
                        <div>
                            <h2 className="text-highlightColor mb-2 text-2xl text-center">App Development</h2>
                            <p className="md:mx-[25%] mx-[5%] text-textColor_2 text-center">I currently switch from React-Native to
                                Swift because of many different reasons. Working on a small specialized Fitness App to
                                improve cardiovascular health. Maybe open-Sourcing it soon</p>
                        </div>
                        <div>
                            <h2 className="text-highlightColor mb-2 text-2xl text-center">Game Development</h2>
                            <p className="md:mx-[25%] mx-[5%] text-textColor_2 text-center">Currently Working on a very small
                                project on the side, soon to be revealed. I program mainly in Godot using GDScript
                                (built in
                                scripting language). Please note that i do all the Music, Art and
                                Writing myself so it does take a lot of time.</p>
                        </div>
                    </div>
                </div>
                <div className="space-y-10">
                    <h2 className="text-highlightColor mb-5 text-4xl underline text-center">Other</h2>
                    <div className="space-y-6">
                        <div>
                            <h2 className="text-highlightColor mb-2 text-2xl text-center">Biotech</h2>
                            <p className="md:mx-[25%] mb-14 mx-[5%] text-textColor_2 text-center">A huge interest of mine is the intersection of IT (especially AI - AGI) and biology. Inspired by visionaries like Bryan Johnson or Dr. Anne Brunet, the potential to drive significant innovations in health and longevity science deeply motivates me to pursue further (academic) studies in this field.</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Layout>
    );
}

export default Home;
