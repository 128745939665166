function StatusListElement ({checked, Title} ) {

    if (checked === true){
        return(
            <div className="flex justify-center">
                <span className="text-highlightColor pr-2">✔</span>
                <span className="text-textColor_2">{Title}</span>
            </div>
        )
    }
    else {

        return (
            <div className="flex justify-center">
                <span className="text-bordeauxRed pr-2">✘</span>
                <span className="text-textColor_2">{Title}</span>
            </div>
        );
    }
}

export default StatusListElement