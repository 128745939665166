import '../styles/App.css';
import Layout from "../components/Layout";
import StatusListElement from "../components/StatusListElement";

function Technologies() {
    return (
        <Layout>
            <div>
                <div className="text-2xl text-textColor_2 flex justify-center mb-[2%]">
                    Status of the Website
                </div>
                <div className="grid content-start items-start mb-64">
                    <StatusListElement Title={"Auto-Deploy"} checked={true}/>
                    <StatusListElement Title={"Homepage"} checked={true}/>
                    <StatusListElement Title={"Legal Page"} checked={true}/>
                    <StatusListElement Title={"Routes to all Pages"} checked={true}/>
                    <StatusListElement Title={"Link Socials"} checked={false}/>
                    <StatusListElement Title={"About Page"} checked={false}/>
                    <StatusListElement Title={"Techstack"} checked={false}/>
                    <StatusListElement Title={"Contact Form"} checked={false}/>
                    <StatusListElement Title={"blog.ndrecaj.dev"} checked={false}/>
                    <StatusListElement Title={"More..."} checked={false}/>
                </div>
            </div>
        </Layout>
    );
}

export default Technologies;