import React from 'react';

const LoadingScreen = () => {
    return (
        <div>
            <div className="flex justify-center items-center mt-[15%] mb-[5%] animate-pulse">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-highlightColor"></div>
            </div>
            <span className="text-highlightColor flex justify-center text-2xl">Fixing Bugs...</span>
        </div>
    );
};

export default LoadingScreen;