import React, { useState, useEffect } from 'react';
import '../styles/TypingEffect.css';

const TypingEffect = ( ) => {
    const texts = ['IOS Developer ', 'Computer Scientist ', 'Game Developer ', 'Full-Stack Web Developer ', 'IT-Security Professional'];
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        if (subIndex === texts[index].length + 1 && !reverse) {
            setTimeout(() => setReverse(true), 1000); // Warten, bevor das Löschen beginnt
            return;
        }

        if (subIndex === 0 && reverse) {
            setTimeout(() => {
                setReverse(false);
                setIndex((index + 1) % texts.length); // Zum nächsten Text wechseln
            }, 0);
            return;
        }
        const timeout = setTimeout(() => {
            setText(texts[index].substring(0, subIndex));
            setSubIndex(subIndex + (reverse ? -1 : 1));
        }, 120); // Geschwindigkeit des Schreibens/Löschens

        return () => clearTimeout(timeout);
    }, [subIndex, index, reverse, texts]);

    return <div className="typing-container">
            <span className="cursor"></span> {/* Cursor hinzugefügt */}
            <span className="typing-effect">{text}</span>
        </div>;
};

export default TypingEffect;