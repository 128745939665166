import '../styles/App.css';
import Layout from "../components/Layout";
import {Link} from "react-router-dom";
import React from "react";

function Technologies() {
    return (
        <Layout>
            <div>
                <div className="grid justify-center text-textColor_1 text-center">
                    <h1 className="text-5xl">Technologies</h1>
                    <Link to={`./status`} className="flex border-4 justify-center border-highlightColor mt-4 hover:bg-highlightColor rounded-2xl p-2 hover:text-third_color transition-all">
                        status of the Website
                    </Link>
                </div>

                <div className={"md:block hidden"}>
                <div className={"grid mb-[5%] justify-center border-2 mt-[2%] bg-primaryColor border-highlightColor text-textColor_1 rounded-2xl text-center ml-[2%] mr-[2%] grid-cols-3"}>
                    <div className="border-r-2 border-highlightColor pt-2 rounded-l-2xl flex-grow justify-center">
                        <a className=" text-4xl font-bold border-b-highlightColor border-b-2">Web</a>
                        <div className="mt-[4%] text-2xl underline">Frontend</div>
                        <div>React</div>
                        <div>TailwindCSS</div>
                        <div>Next.JS</div>
                        <div>Material UI</div>
                        <div>shadcn/ui</div>

                        <div className="mt-[4%] text-lg underline">Backend</div>
                        <div>Django</div>
                        <div>PostgreSQL</div>
                        <div>MongoDB</div>

                        <div className="mt-[4%] text-lg underline">DevOps - Hosting</div>
                        <div>Docker</div>
                        <div>NGINX</div>
                        <div>Vercel</div>
                        <div className="mb-[2%]">Stripe</div>
                    </div>

                    <div className="border-r-2 border-highlightColor pt-2 rounded-l-2xl flex-grow justify-center pb-2">
                        <a className=" text-4xl font-bold border-b-highlightColor border-b-2">Mobile</a>
                        <div className="mt-[4%] text-2xl underline">Frameworks</div>
                        <div>React Native</div>
                        <div>expo</div>

                        <div className="mt-[4%] text-2xl underline">Sprachen</div>
                        <div>JavaScript</div>
                        <div>TypeScript</div>
                    </div>

                    <div className="pt-2 rounded-l-2xl flex-grow justify-center">
                        <a className="text-4xl font-bold border-b-highlightColor border-b-2">Other</a>
                        <div className="mt-[4%] text-2xl underline">Desktop</div>
                        <div>Electron</div>

                        <div className="mt-[4%] text-2xl underline">Spiele</div>
                        <div>Godot</div>
                        <div>GodotScript</div>

                        <div className="mt-[4%] text-2xl underline">All Purpose</div>
                        <div>Python</div>
                    </div>
                </div>
                </div>
                <div className={"md:hidden flex justify-center"}>
                    <a className={"mt-24 mb-24 text-textColor_1 text-xl"}>
                        Ony available on Desktop
                    </a>
                </div>
            </div>
        </Layout>
    );
}

export default Technologies;