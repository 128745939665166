import React from 'react';

const HighlightedBtn = () => (
    <a href={"https://www.ndrecaj.dev/contact"} className="h-2 w-36">
                  <span
                      className={'border-2 hover:text-highlightColor rounded-3xl text-textColor_2 hover:shadow-third_color transition-all hover:shadow-md hover:border-r-4 hover:border-b-4 border-highlightColor ml-1 hover:ml-0.5 p-2'}>
                      Contact Me
                  </span>
    </a>
);

export default HighlightedBtn;